/* Reset default browser styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* App container */
.App {
    font-family: 'Exo 2', sans-serif;
    background-color: white; /* Ensure background stays white */
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

/* Header styles */
header {
    background-color: transparent;
    padding: 0;
    text-align: center;
}

/* Header title */
header h1 {
    font-size: 24px;
    margin: 0; /* Remove all margins to avoid any boxes */
}

/* Header subtitle */
header p {
    font-size: 16px;
    margin: 0;
}

/* Main content area */
main {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto; /* Enable vertical scrolling */
    width: 100%;
}

/* Chat container */
.chat-container {
    width: 95%;
    max-width: 400px;
    flex-grow: 1 1 auto; /* Allow the chat container to grow as needed */
    display: flex;
    flex-direction: column;
    align-items: stretch; /* Align messages at the top */
    justify-content: flex-start; /* Ensure the messages start from the top */
    overflow-y: auto; /* Allow vertical scrolling */
    overflow-x: hidden; /* Prevent horizontal scroll */
    padding: 10px;
    background-color: white; /* Ensure background is always visible */
    box-shadow: none;
    border-radius: 0;
    scroll-behavior: smooth; /* Ensure smooth scrolling */
    min-height: 100%;
    margin-bottom: 60px; /* Prevent overlap with input form at the bottom */
}

/* Custom Scrollbar */
.chat-container::-webkit-scrollbar {
    width: 8px; /* Slim scrollbar */
}

.chat-container::-webkit-scrollbar-thumb {
    background-color: #06b6d4; /* Match blue color */
    border-radius: 5px;
}

.chat-container::-webkit-scrollbar-track {
    background-color: #f0f0f0; /* Light grey track */
}

.chat-container:hover::-webkit-scrollbar-thumb {
    background-color: #007bb5; /* Darker blue on hover */
}

/* Input form */
.input-form {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 0;
    background-color: white;
    border-top: 1px solid #ccc;
    position: sticky; /* Ensure the input form stays fixed at the bottom */
    bottom: 0; /* Ensures it remains at the bottom */
    padding-left: 10px;  /* NEW: Add left padding to the form */
    padding-right: 10px; /* NEW: Add right padding to the form */
}

/* Input field */
input {
    padding: 10px;
    font-size: 16px;
    width: 100%;
    border: 2px solid #06B6D4;
    border-radius: 4px;
    margin-right: 10px; /* Ensures spacing between input and send button */
    background-color: #FFFFFF;
    box-sizing: border-box;
}

/* Send button */
button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #06B6D4;
    color: white;
    border: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #005f73;
}

/* Message styles */
.message {
    width: 100%; /* Ensure messages take full width */
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    word-wrap: break-word; /* Ensure long messages wrap */
}

.user-message,
.bot-message {
    padding: 10px;
    border-radius: 15px;
    max-width: 100%; /* Prevent overflow beyond container */
    word-wrap: break-word; /* Ensure long messages wrap properly */
}

.user-message {
    background-color: #06B6D4;
    color: white;
    align-self: flex-end;
}

.bot-message {
    background-color: #f0f0f0;
    color: black;
    align-self: flex-start;
    border: 2px solid #06B6D4;
}

.message a {
    color: #06B6D4;
    text-decoration: underline;
}

/* Scrollbar styles for messages */
.chat-container::-webkit-scrollbar {
    width: 8px;
}

.chat-container::-webkit-scrollbar-thumb {
    background-color: #06b6d4;
    border-radius: 5px;
}

.chat-container::-webkit-scrollbar-track {
    background-color: #f0f0f0;
}

/* Initial prompt styling */
.initial-prompt {
    font-size: 16px;
    color: #333;
    margin-bottom: 10px;
    background-color: transparent;
}
